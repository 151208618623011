export default {
    aboutInfo: {
        title: "技术支持",
        desc: "我们提供专业的技术支持团队，随时为客户解答产品使用和故障排除方面的问题。",
        desc1: "可以通过电话、电子邮件及在线支持平台联系我们的团队。",
    },

    companyInfo: {
        title: "天宸绿色能源科技（芜湖）有限公司",
        address: "上海市浦东新区银城中路168号上海银行大厦7F",
        map: "查看地图",
        wxTip: '微信公众号',
    },
}
export default {
    form: {
        title: "预约咨询",
        input: {
            mobile: {
                label: "手机号码",
                placeholder: "请输入正确手机号码",
            },
            // 验证码
            verifyCode: {
                label: "验证码",
                placeholder: "请输入验证码",
                btn: "获取验证码"
            }
        },
        tips: "专业客服会根据预约情况与您联系，请耐心等待。",
        submit: "点击提交"
    }
}
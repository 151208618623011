<template>
  <v-container fluid class="py-0 px-0">
    <div class="header-image">
      <v-img src="@/assets/images/project/v100/header.png" width="100%"></v-img>

      <div class="about-info-xs d-flex justify-center align-center" v-if="$vuetify.breakpoint.smAndDown">
            <div class="about-info-title text-center">{{ $t("project.part1") }}</div>
      </div>

      <div class="about-info" v-else>
        <v-row>
          <v-col cols="2"></v-col>
          <v-col cols="8" class="pa-0">
            <div class="about-info-title text-center">{{ $t("project.part1") }}</div>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </div>
    </div>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10" class="main">

        <!--图文-->
        <v-row id="part2" class="part2">
          <v-col xs="12" sm="6" md="6" lg="4" xl="4" class="l-item"
                 v-for="(item, index) in list"
                 :key="index">
            <div class="box">
              <div class="text">
                <div class="txt-1">{{ item.title }}</div>
                <div class="txt-2">{{ item.text }}</div>
              </div>
              <v-img class="image" :src="item.img" width="100%"></v-img>
            </div>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import {gsap} from "gsap";
import {useLanguage} from "@/stores";

export default {
  name: "Project",

  data() {
    return {
      // 图文列表
      list: []
    }
  },

  computed: {
    languageStore() {
      return useLanguage();
    },
  },

  mounted() {
    window.scrollTo(0, 0)

    this._getList();

    this._watchGetList();

    this._animateHeaderImage();


    gsap.from(".part2", {
      y: 50,
      opacity: 0,
      duration: 1
    });
  },

  methods: {
    _animateHeaderImage() {
      // 顶部图片
      gsap.from(".header-image", {
        y: 10,
        opacity: 0,
        duration: 1.5
      })

      //
      gsap.from(".about-info", {
        y: 10,
        opacity: 0,
        duration: 1.5,
        delay: 0.5
      })
    },

    _getList() {
      this.list = [
        {
          title: this.$t("project.part2.0.title"),
          text: this.$t("project.part2.0.content"),
          img: require('@/assets/images/project/v100/1.jpg')
        },
        {
          title: this.$t("project.part2.1.title"),
          text: this.$t("project.part2.1.content"),
          img: require('@/assets/images/project/v100/2.jpg')
        },
        {
          title: this.$t("project.part2.2.title"),
          text: this.$t("project.part2.2.content"),
          img: require('@/assets/images/project/v100/3.jpg')
        },
      ]
    },

    _watchGetList() {
      // watch 监听 $t 切换
      useLanguage().$subscribe((mutation, state) => {
        console.log("$subscribe", this.languageStore.lang, mutation, state.lang);
        this._getList();
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  padding-top: 67px !important;
  padding-bottom: 20px !important;
}

.row {
  margin: 0 !important;
}

.col {
  padding: 0 !important;
}

.header-image {

  position: relative;
  top: 0;

  .about-info {
    position: absolute;
    top: 77px;
    color: #101010;
    width: 100%;

    .about-info-title {
      font-size: 34.31px;
    }
  }

  .about-info-xs {
    position: absolute;
    top: 0px;
    color: #101010;
    width: 100%;
    height: 100%;

    .about-info-title {
      font-size: 24.31px;
    }
  }
}

.main {
  line-height: 1;
  padding: 0 !important;

  .part2 {
    margin-top: 86px !important;

    .l-item {
      margin-bottom: 20px !important;
      padding: 0 6px !important;

      .box {
        position: relative;

        .text {
          width: 100%;
          position: absolute;
          top: 44px;
          z-index: 1;
          color: #ffffff;
          text-align: center;
          padding: 0 24px;;


          .txt-1 {
            font-size: 15.75px;
            line-height: 20px;
          }

          .txt-2 {
            margin-top: 14px;
            font-size: 13.5px;
            line-height: 20px;
            //font-weight: bold;
            //font-weight: unset;
          }
        }

        .image {
          border-radius: 2px;
        }

      }
    }
  }
}
</style>
// import Vue from "vue";
// import config from "@/config.js";
import http from "@/util/http";

const api = {
    add: "book/add",
}

//  获取新闻列表
function BookAdd(param) {
    const params = Object.assign({}, param)
    return http.post(api.add, params)
    // return new Promise((resolve, reject) => {
    //     return Vue.axios.post(config.apiUrl + api.add, params).then((response) => {
    //         resolve(response.data)
    //     }).catch(error => {
    //         reject(error)
    //     })
    // })
}

export {
    BookAdd,
}
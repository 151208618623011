export default {
    title: "TC  ENERTECH",
    desc: "Tech Makes Extraordinary",
    learnMoreBtn: "Learn More",

    address:"7F, Shanghai Bank Tower, 168 Yincheng Middle Road, Pudong New Area, Shanghai",
    tel:"021-8888 8888",
    email:"tcenertech@tcenertech.com",

    moreTit:"consult",
    morePhone:"Phone",
    moreName:"Name",
    moreTips:"Professional customer service will contact you, please be patient.",
    moreSend:"Recognize",
    moreSendSucc:"Your information has been successfully submitted.",
}
export default {
    part1: "Project investment and construction",
    part2: [
        {
            title: "Epc Collaboration",
            content: "Joint development model in the early stage, cooperating with EPC partners to obtain integrated orders",
        },
        {
            title: "User side commissioning",
            content: "The owner intends to be an industry leader, listed company, Fortune 500 company, etc., with high and stable returns",
        },
        {
            title: "Investment in power plant projects",
            content: "Minority interests participate in the acquisition of early indicators, and after participating in the investment, obtain project development rights, product integration delivery, and operation and maintenance rights",
        }
    ],
}
export default {
    navDataList: [
        {
            id: 1,
            name: 'About us',
            path: '/about'
        }, {
            id: 2,
            name: 'Energy storage',
            path: '/product'
        }, {
            id: 3,
            name: 'TC service',
            path: '/solution'
        }, {
            id: 4,
            name: 'Project invest',
            path: '/project'
        }, {
            id: 5,
            name: 'TC news',
            path: '/news'
        }, {
            id: 6,
            name: 'Contact us',
            path: '/contact'
        }, {
            id: 7,
            name: 'Join us',
            path: '/join'
        }
    ]
}
export default {
    header: {
        title: "你好，我们是天宸能科。",
        desc: "天宸绿色能源科技（芜湖）有限公司（TC ENERTECH）是上市公司天宸股份（600620）的全资子公司，",
        desc1: "是一家专业从事新能源光伏板、储能系统、逆变器、EMS等全系储能产品的研发、生产、销售的科技型技术企业。",
    },

    gufen: {
        title: "天宸股份",
        desc: "天宸股份(股票代码“600620.SH”)最具历史及影响力的上市公司之一,成立于1989年,并与1992年正式上市,公司以“远见决定未来”为信念,以可持续发展为标准,经过20余年的多元化的发展与壮大,成为集房地产、大健康产业、交运物流、光储能源等多业态综合性的上市公司。现今,天宸股份在房地产、商贸、能源等多项领域已建立50余个企业，涉及资产规模达到千亿以上。2023年集团正式进入新能源赛道，将充分整合集团优质资源,力争在短期内成为行业名片，成就集团发展的又一个重要产业。",
    },

    lvse: {
        title: "天宸绿色能源科技(芜湖）有限公司",
        desc: "天宸能科是天宸股份（股票代码：600620)全资子公司，总部设在上海，是一家致力于为不同场景提供多元化能源解决方案的科技型企业。集方案设计、项目投建、系统搭建、智能运维等全链条综合性能源服务，同时也能提供储能新能源光伏板、储能系统、逆变器、EMS 等全系储能产品的能源科技公司。",
    },

    wenhua: {
        title: "天宸文化",
        pic: [
            {
                title: "我们的理念",
                content: "科技本然 非凡天成",
            },
            {
                title: "我们的目标",
                content: "成为世界领先的能源科技公司",
            },
        ]
    }
}
import http from '@/util/http';

const api = {
    list: "news/list",
    view: "news/view",
}

//  获取新闻列表
function NewsList(param) {
    const params = Object.assign({
        newType: 1,
        page: 1,
        pageSize: 2,
    }, param)
    return http.get(api.list, params)
    // return new Promise((resolve, reject) => {
    //     return Vue.axios.get(config.apiUrl + api.list + "?page=" + params.page +"&pageSize=" + params.pageSize + "&newsType=" + params.newType).then((response) => {
    //         resolve(response.data)
    //     }).catch(error => {
    //         reject(error)
    //     })
    // })
}

// 新闻详情
function NewsView(param) {
    const params = Object.assign({
        id: 0,
    }, param)
    return http.get(api.view, params)
    // return new Promise((resolve, reject) => {
    //     return Vue.axios.get(config.apiUrl + api.view + "?id=" + params.id).then((response) => {
    //         resolve(response.data)
    //     }).catch(error => {
    //         reject(error)
    //     })
    // })
}

export {
    NewsList,
    NewsView,
}
export default {
    title: "天宸能科",
    desc: "科技本然 非凡天成",
    learnMoreBtn: "了解更多",

    address:"上海市浦东新区银城中路168号上海银行大厦7F",
    tel:"021-8888 8888",
    email:"tcenertech@tcenertech.com",

    moreTit:"预约咨询",
    morePhone:"手机号码",
    moreName:"姓名",
    moreTips:"专业客服会根据预约情况与您联系，请耐心等待。",
    moreSend:"点击提交",
    moreSendSucc:"您的信息已成功提交~",
}
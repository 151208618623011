import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n/index.js'
import {createPinia, PiniaVuePlugin} from "pinia";
import router from './router'

Vue.config.productionTip = false

Vue.config.errorHandler = (err) => {
    console.log("errorHandler", err);
}

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

new Vue({
    router,
    vuetify,
    pinia,
    i18n,
    render: h => h(App)
}).$mount('#app')

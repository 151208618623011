export default {
    aboutInfo: {
        title: "technical support",
        desc: "We provide a professional technical support team to answer customers' questions about product use and troubleshooting at any time.",
        desc1: "You can contact our team through phone, email, and online support platforms.",
    },

    companyInfo: {
        title: "TC GREEN ENERTECH Technology (Wuhu) Co., Ltd",
        address: "7F, Shanghai Bank Tower, 168 Yincheng Middle Road, Pudong New Area, Shanghai",
        map: "Map",
        wxTip: 'WeChat official account',
    },
}
export default {
    aboutInfo: {
        title: "Our energy storage products",
        desc: "The Tianchen Energy Storage Management Team has rich experience in project management and delivery",
        desc1: "and has full chain industrial capabilities in scheme design, project investment and construction, system construction, and intelligent operation and maintenance",
    },
    part3: [
        {
            title: "User energy storage system",
            content: "Including household series wall mounted and stacked battery packs, household stacked all-in-one machine, rack mounted UPS battery pack series, etc"
        }, {
            title: "Industrial and commercial energy storage system",
            content: "Self developed energy storage battery system+inverter system; Large capacity, high power density, and small footprint; Equipped with multi-level protection function and high security; Highly integrated, easy to construct, flexible to deploy, and widely used."
        }, {
            title: "Centralized energy storage system",
            content: "The system adopts a modular design, integrating energy storage batteries, BMS, energy storage converters, temperature control, power distribution, fire protection, and lighting subsystems into one; Highly modular, easy to transport, install, and maintain; Unique BMS and PACK technologies enhance battery life. "
        },
    ],
    detail: {
        tips: "Click to view larger image"
    }
}
import axios from 'axios' // 使用前要先安装依赖：npm install axios
import config from "@/config.js";
import {useSnackbar} from "@/stores/modules/snackbar";

// 创建axios实例
const service = axios.create({
    // 这里可以放一下公用属性等。
    baseURL: config.apiUrl, // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。
    withCredentials: false, // 跨域请求时是否需要访问凭证
    timeout: 60 * 1000, // 请求超时时间
    // 请求头
    headers: {}
})

// 请求拦截器
service.interceptors.request.use(config => {
    // 这里可以进行请求加密等操作。如添加token,cookie，修改数据传输格式等。
    config.headers['Content-type'] = 'application/json';
    return config;
},  (error) => {
    // 对请求错误做些什么
    console.log("request-error", error);
    return Promise.reject(error);
})

service.interceptors.response.use(response => {
    // 对响应数据做点什么
    // console.log("response", response);
    if(response.status === 200) {
        //对数据进行处理,如：脱壳
        let {data} = response
        return data
    } else {
        return Promise.reject(error);
    }
}, error => {
    useSnackbar().openSnackbar({
        msg: error.message,
        color: 'error'
    });
    // 对响应错误做点什么
    console.log("response-error", error);
    return Promise.reject(error);
})

export default service;

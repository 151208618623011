export default {
    aboutInfo: {
        title: "我们的储能产品",
        desc: "天宸储能管理团队具有丰富项目管理交付经验",
        desc1: "具备方案设计、项目投建、系统搭建、智能运维的全链条产业能力",
    },
    part3: [
        {
            title: "用户储能系统",
            content: "包含户用系列壁挂式、堆t叠式电池组，户用堆叠式一体机，机架式ups电池组系列等"
        }, {
            title: "工商业储能系统",
            content: "自研储能电池系统+逆变器系统；容量大、功率密度高、占地面积小；具有多级保护功能，安全性高；高度集成，施工简单，部署灵活，用途广泛。"
        }, {
            title: "集中式储能系统",
            content: "系统采用模块化设计、集储能电池、BMS、储能转换器、温控、配电、消防、照明子系统于一体；高度模块化，便于运输、安装和维护；独创的BMS和PACK技术提升电芯寿命。"
        },
    ],
    detail: {
        tips: "单击查看大图"
    }
}
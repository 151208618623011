export default {
    part1: "项目投建",
    part2: [
        {
            title: "Epc合作",
            content: "前期共同开发模式，与EPC合作方配合，获取集成订单",
        },
        {
            title: "用户侧投运",
            content: "业主方意向为行业龙头、上市公司、世界500强企业等，收益高而稳定",
        },
        {
            title: "电站项目投资",
            content: "少数权益参与前期指标获取，参投后获取项目开发权、产品集成交付和运维权",
        }
    ],
}
export default {
    yiti:{
        title: "一体化综合解决方案",
        desc: "我们不断开拓新的应用场景，包括但不限于家庭能源储备、商业能源管理、工业能源存储等领域。同时，根据不同场景的需求，提供更多定制化的产品和解决方案",
    },

    dianwang: {
        title: "24h微电网优势",
        desc: {
            item1: {
                title: "可持续性",
                desc: "减少对传统能源的依赖，降低能源消耗和环境污染",
            },
            item2: {
                title: "稳定性",
                desc: "通过智能能源管理系统，与传统电网协同运行，稳定可靠",
            },
            item3: {
                title: "高效性",
                desc: "降低能源浪费和电能损耗，实现能源最优化配置",
            },
            item4: {
                title: "弹性和灵活性",
                desc: "根据用户需求和能源供给进行调整优化，适应不同工况和环境要求",
            },
        }
    },
}
export default {
    navDataList: [
        {
            id: 1,
            name: '关于我们',
            path: '/about'
        }, {
            id: 2,
            name: '储能产品',
            path: '/product'
        }, {
            id: 3,
            name: '解决方案',
            path: '/solution'
        }, {
            id: 4,
            name: '项目投建',
            path: '/project'
        }, {
            id: 5,
            name: '资讯中心',
            path: '/news'
        }, {
            id: 6,
            name: '联系我们',
            path: '/contact'
        }, {
            id: 7,
            name: '加入我们',
            path: '/join'
        }
    ]
}
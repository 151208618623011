// import Vue from "vue";
// import config from "@/config.js";

import http from '@/util/http';

const api = {
    all: "news/cate/all",
}

function NewsCateAll() {
    return http.get(api.all)
    // return new Promise((resolve, reject) => {
    //     return Vue.axios.get(config.apiUrl + api.all).then((response) => {
    //         resolve(response.data)
    //     }).catch(error => {
    //         reject(error)
    //     }).finally(() => {
    //         // console.log("网络错误")
    //     })
    // })
}

export {
    NewsCateAll
}
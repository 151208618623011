export default {
    form: {
        title: "consult",
        input: {
            mobile: {
                label: "Phone",
                placeholder: "Please enter the correct phone number",
            },
            // 验证码
            verifyCode: {
                label: "Captcha",
                placeholder: "SMS authentication code",
                btn: "send"
            }
        },
        tips: "Professional customer service will contact you, please be patient.",
        submit: "Recognize"
    }
}
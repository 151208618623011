<template>
  <v-app>
    <!--菜单-->
    <v-navigation-drawer
        app

        v-model="drawer"
        temporary
        right
    >
      <v-list
      >
        <div class="drawer-nav-item" :class="[navDataIndex === index ? 'active' : '']"
             v-for="(item, index) in $t('nav.navDataList')" :key="index" @click="handleNavigate(index)">
          {{ item.name }}
        </div>
        <div class="drawer-nav-item">
          <v-list outlined>
            <v-list-item outlined
                         v-for="(item, index) in languageDataList"
                         :key="index"
                         link
                         @click="handleChangeLanguage(item.value)"
            >
              <v-list-item-content class="justify-center">{{ item.text }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        class="app-bar"

        app
        :color="appBarBgColor"
        :flat="true"
        max-height="67"
    >
      <v-row>
        <!--logo-->
        <v-col cols="6" sm="6" md="6" lg="3" xl="3" class="pa-0">
          <a href="/">
            <v-img
                src="@/assets/images/logo.png"
                max-width="139"
                max-height="34"
            ></v-img>
          </a>
        </v-col>

        <!--链接-->
        <v-col cols="6" v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
          <!--          <v-row class="menu d-flex align-center justify-center">-->
          <!--            <v-col cols="1" class="li" :class="[navDataIndex === index ? 'active' : '']" :style="{color: appBarLiColor}"-->
          <!--                   v-for="(item, index) in $t('nav.navDataList')" :key="index" @click="handleNavigate(index)">-->
          <!--              {{ item.name }}-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <div class="menu d-flex align-center justify-center">
            <div class="li" :class="[navDataIndex === index ? 'active' : '']" :style="{color: appBarLiColor}"
                 v-for="(item, index) in $t('nav.navDataList')" :key="index" @click="handleNavigate(index)">
              {{ item.name }}
            </div>
          </div>
        </v-col>

        <!--语言切换按钮-->
        <v-col v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" cols="6" sm="6" md="6" lg="3" xl="3"
               class="pa-0 d-flex justify-end">

          <v-menu
              open-on-hover
              bottom
              offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="change_language d-flex align-center" :style="{color: appBarLiColor}">
                <span class="iconfont icon-language"></span>
                {{ curLanguage }}
              </div>
            </template>

            <v-list outlined>
              <v-list-item outlined
                           v-for="(item, index) in languageDataList"
                           :key="index"
                           link
                           @click="handleChangeLanguage(item.value)"
              >
                <v-list-item-content>{{ item.text }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

        </v-col>

        <!--打开抽屉按钮-->
        <v-col cols="6" sm="6" md="6" lg="4" xl="4" class="pa-0 d-flex justify-end" v-else>
          <v-app-bar-nav-icon
              @click="drawer = true"
          >
          </v-app-bar-nav-icon>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>

      <div class="icons">
        <div class="btn trans3 d-flex align-center justify-center mapBtn animated fadeInRight">
          <div class="iconfont icon-dingwei"></div>
          <div class="msgBox trans3 d-flex align-center justify-center">
            {{ $t('home.address') }}
          </div>
        </div>
        <!--        <div class="btn trans3 d-flex align-center justify-center telBtn animated fadeInRight del02">-->
        <!--          <div class="iconfont icon-call"></div>-->
        <!--          <div class="msgBox trans3 d-flex align-center justify-center">-->
        <!--            {{ $t('home.tel') }}-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="btn trans3 d-flex align-center justify-center atBtn animated fadeInRight del02">
          <div class="iconfont icon-youxiang"></div>
          <div class="msgBox trans3 d-flex align-center justify-center">
            {{ $t('home.email') }}
          </div>
        </div>
      </div>

    </v-main>

    <v-footer

        padless
        class="v-footer"
        v-if="isShowFooter"
    >
      <div class="footer">
        <v-divider></v-divider>
        <div class="txt d-flex flex-column justify-center align-center flex-sm-row">
          <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">
            <div class="">皖 ICP 备 2023016662 号 -1</div>
          </a>
          <v-img src="@/assets/images/badge.png" alt="" :max-width="15" class="badge"></v-img>
<!--          <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302001865" target="_blank">-->
<!--            <div class="">沪公网安备 31011402003368</div>-->
<!--          </a>-->
        </div>
      </div>
    </v-footer>

    <Snackbar/>

  </v-app>
</template>

<script>

import {
  languageDataList
} from '@/i18n/config.js'

import {
  useLanguage
} from '@/stores'

import Snackbar from '@/components/Snackbar/Snackbar.vue'

export default {
  components: {
    Snackbar
  },

  data() {
    return {
      // 背景
      appBarBgColor: "rgba(1,1,1,0)",

      // 颜色
      appBarLiColor: "#ffffff",

      // 当前语言
      curLanguage: useLanguage().GetLanguage().text,

      // 语言列表
      languageDataList: languageDataList,

      // 抽屉
      drawer: false,

      // 导航
      navDataIndex: -1,

      // 导航列表
      navDataList: this.$t('nav.navDataList'),

      // 控制底部显示
      isShowFooter: true,
    }
  },

  mounted() {
    // console.log("useLanguage", useLanguage().GetLanguage())
    // console.log("this.$router", this.$router.history.current.path);
    // console.log("this.$t.nav", this.$t('nav.navDataList'))

    // console.log("this.$route.path", this.$route.path);

    for (let i = 0; i < this.navDataList.length; i++) {
      // console.log("this.navDataList[i].path",this.navDataList[i].path);
      // console.log("this.navDataList[i].path1111111",this.$route.path.search(this.navDataList[i].path));
      // // if (this.navDataList[i].path === this.$route.path) {
      if (this.$route.path.search(this.navDataList[i].path) !== -1) {
        this.navDataIndex = i;
        break;
      }
    }

    this._setNavBar();
  },

  methods: {
    // 跳转
    handleNavigate(index) {
      this.navDataIndex = index;

      // if(
      //     index === 0 ||
      //     index === 1 ||
      //     index === 2 ||
      //     index === 3 ||
      //     index === 5
      // ) {
      //   return false;
      // }

      this.$router.push(this.navDataList[index].path);
      this._setNavBar();
    },

    // 切换语言
    handleChangeLanguage(lang) {
      useLanguage().SetLanguage(lang);
      this.curLanguage = useLanguage().GetLanguage().text;
      this.$i18n.locale = lang
    },

    // 设置导航和底部
    _setNavBar() {
      // console.log("_setNavBar", this.$router, this.$route.path)
      if (this.$route.path === '/' || this.$route.path === '/home') {
        this.appBarBgColor = "rgba(1,1,1,0)";
        this.appBarLiColor = "#ffffff";
        this.isShowFooter = false;
      } else {
        this.appBarBgColor = "#ffffff";
        this.appBarLiColor = "#000000";
        this.isShowFooter = true;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.drawer-nav-item {
  height: 50px;
  //background: red;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  font-size: 15px;

  &:hover {
    background-color: #f3f3f3;
  }

  &.active {
    font-weight: bold;
  }

}

.app-bar {
  .row {
    margin: 0 !important;
  }

  .col {
    padding: 0 !important;
  }

  .menu {
    height: 100%;

    .li {
      color: #ffffff;
      font-size: 15px;
      cursor: pointer;
      text-align: center;
      margin: 0 20px !important;
      white-space: nowrap;

      &.active {
        font-weight: bold;
      }

      &:hover {
        font-weight: bold;
      }

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  .change_language {
    cursor: pointer;
    //position: absolute;
    //right: 50px;
    //top: 25px;
    //margin-right: 70px;
    font-size: 16px;
    //color: #ffffff;

    .iconfont {
      font-size: 26px;
      display: block;
      margin-right: 10px;
    }

    //&:hover{color: black;}
  }

}

.v-footer {
  background-color: #ffffff;

  .footer {
    width: 100%;
    text-align: center;

    .txt {
      height: 64px;
      padding-top: 22px;
      color: #cecfd7;
      font-size: 14px;

      //display: flex;
      //align-items: center;
      //justify-content: center;

      .badge {
        margin: 0 10px 0 12px;
      }
    }
  }
}

// 右下角联系
.icons {
  position: fixed;
  right: 30px;
  bottom: 20px;
  z-index: 20;

  .btn {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, .7);
    border-radius: 100%;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;

    .iconfont {
      font-size: 20px;
    }

    .icon-call {
      font-weight: bold;
    }

    &:hover {
      background-color: black;
      color: white;

      .msgBox {
        opacity: 1;
        transform: translate(0px, 0);
        right: 70px;
      }
    }

    .msgBox {
      height: 55px;
      padding: 0 20px;
      position: absolute;
      background-color: white;
      right: -100vh;
      opacity: 0;
      transform: translate(10px, 0);
      white-space: nowrap;
      color: black;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 9px solid white;
        position: absolute;
        right: -8px;
      }
    }
  }
}
</style>

<style>
/*自定义字体*/
@import "@/assets/font/iconfont.css";
@import "@/assets/css/animate.css";
</style>

<style lang="scss">
.v-main {
  padding: 0 !important;
  background-color: #ffffff !important;
}

// 通用按钮hover & mouseout动画
.animated-eve-btn {
  position: relative;

  &:hover {
    .txt-out {
      transform: translate(0, -20px);
      opacity: 0;
    }

    .txt-in {
      transform: translate(0, 0px);
      opacity: 1;
    }
  }

  .txt-out {
    position: absolute;
  }

  .txt-in {
    opacity: 0;
    transform: translate(0, 20px);
    font-weight: bold;
  }
}
</style>

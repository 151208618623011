export default {
    listPart: {
        left: {
            title: "Join us"
        },
        right: {
            search: {
                placeholder: 'Search for position',
                searchBtn: 'Search'
            },
            list: {
                money: "salary"
            }
        }
    },

    detailPart: {
        money: "salary",
        descTitle: "Description",
        detailBtn: {
            text: "Resume submission",
            tips: "Click to copy the delivery address"
        }
    }
}
<template>
  <v-container fluid class="px-0 py-0">

    <div class="header-image">
      <v-img class="" src="@/assets/images/join/1.jpg" width="100%"></v-img>

      <div class="about-info-xs d-flex justify-center align-center" v-if="$vuetify.breakpoint.xsOnly">
        <div class="about-info-title">{{ $t('join.listPart.left.title') }}</div>
      </div>

      <div class="about-info" v-else>
        <div class="about-info-title">{{ $t('join.listPart.left.title') }}</div>
      </div>

    </div>

    <v-row v-if="!detailId">
      <v-col cols="1" sm="1" md="3" ></v-col>
      <v-col cols="10" sm="10" md="6" class="main pa-0 d-flex flex-column align-center">

        <div class="join-search">
          <div class="icon"><span class="mdi mdi-magnify"></span></div>
          <input class="input-poster" type="text" :placeholder="$t('join.listPart.right.search.placeholder')"
                 v-model="searchSalaryName">
          <div class="btn-poster" @click="handleSearch()">{{ $t('join.listPart.right.search.searchBtn') }}</div>
        </div>
        <div class="list">
          <div class="list-item" v-for="(item, index) in list" :key="index" @click="handleView(index)">
            <div class="list-item-title">{{ item.title }}</div>
            <div class="list-item-info">{{ item.address }} | {{
                $t('join.listPart.right.list.money')
              }}：{{ item.salary }}
            </div>
            <div class="join-list-item-desc" v-html="item.dc"></div>
          </div>
        </div>

        <div class="bottom" v-if="allListCount > 0">
          <v-pagination
              v-model="pageNum"
              :length="allPage"
              color="#1d2088"
              @next="handleNextPage"
              @previous="handlePrevPage"
              @input="handleInputPage"
          ></v-pagination>
        </div>

      </v-col>
      <v-col cols="1" sm="1" md="3" ></v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="1" md="3"></v-col>
      <v-col cols="10" md="6" class="main pa-0">
        <!--详情-->
        <div class="detail">
          <div class="detail-title">{{ detail.title }}</div>
          <div class="detail-info">{{ detail.address }} | {{ $t('join.detailPart.money') }}：{{ detail.salary }}</div>
          <div class="detail-desc">
            <div class="desc-title">
              <div class="line"></div>
              <div class="text">{{ $t('join.detailPart.descTitle') }}</div>
            </div>
            <div class="join-desc-content">
              <div v-html="detail.content"></div>
            </div>
          </div>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="detail-btn d-flex flex-column align-center justify-center flex-sm-row "
                   v-bind="attrs"
                   v-on="on"
                   @click="handleCopy()"
              >
                <div class="">{{ $t('join.detailPart.detailBtn.text') }}：</div>
                <div class="">{{ detail.deliveryAddress }}</div>
              </div>
            </template>
            <span>{{ $t('join.detailPart.detailBtn.tips') }}</span>
          </v-tooltip>

        </div>
      </v-col>
      <v-col cols="1" md="3"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getMoreListData} from '@/util/util.js';
// import {gsap} from "gsap";
import {SalaryView, SalaryList} from "@/api/salary";
import {useSnackbar} from "@/stores/modules/snackbar";

import useClipboard from 'vue-clipboard3'
import {gsap} from "gsap";

export default {

  // eslint-disable-next-line vue/multi-word-component-names
  name: "Join",

  data() {
    return {
      pageNum: 1,

      allPage: 1,

      allListCount: 0,

      list: [],

      detail: {
        id: '',
        title: '',
        address: '',
        salary: '',
        content: ''
      },

      detailId: false,

      searchSalaryName: "",

      // 没有更多
      isNoMore: false,
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    this._animateHeaderImage();

    // 设置搜索薪资名称
    this.searchSalaryName = this.$route.params.search;

    // 设置详情 id
    this.detailId = this.$route.params.id;

    if (this.detailId) {
      this._getDetail();
    } else {
      this._getList();
    }
  },

  methods: {
    handlePrevPage() {
      window.scrollTo(0, 0)
      this.list = [];
      if (this.pageNum > 1) {
        this.pageNum--;
      }
      this._getList();
    },

    handleInputPage(e) {
      window.scrollTo(0, 0)
      console.log("handleInputPage", e)
      this.list = [];
      this.pageNum = e;
      this._getList();
    },

    handleNextPage() {
      window.scrollTo(0, 0)
      this.list = [];
      if (this.pageNum < this.allPage) {
        this.pageNum++;
      }
      this._getList();
    },

    // 拷贝简历投递地址
    async handleCopy() {
      console.log("handleCopy");
      // 复制
      try {
        await useClipboard().toClipboard(this.detail.deliveryAddress)
        useSnackbar().openSnackbar({
          msg: "复制成功",
          color: 'success'
        });
      } catch (e) {
        console.error(e);
        console.error('复制失败')
      }
    },

    // 更新职位名称
    handleSearch() {
      this.list = [];
      this.pageNum = 1;

      this.$router.push({
        path: "/join/" + this.searchSalaryName,
      });

      this._getList();
    },

    // 查看详情
    handleView(index) {
      this.detailId = this.list[index].id;
      this.$router.push({
        path: "/join/" + this.detailId,
      });
      this._getDetail();
    },

    _animateHeaderImage() {
      // 顶部图片
      gsap.from(".header-image", {
        y: 10,
        opacity: 0,
        duration: 1.5
      })

      gsap.from(".about-info", {
        y: 10,
        opacity: 0,
        duration: 1.5,
        delay: 0.5
      })
    },

    // 新闻详情
    _getDetail() {
      console.log("_getDetail")
      // 测试
      // this.detail.content = "<img src='/images/1.png' style='width: 100%' />";

      SalaryView({
        id: this.detailId
      }).then((sRes) => {
        // console.log("getDetail", sRes);
        this.detail = sRes.data;
      }).catch().finally();
    },

    // 列表数据
    _getList() {
      SalaryList({
        salaryName: this.searchSalaryName,
        page: this.pageNum,
        pageSize: this.pageSize,
      }).then((eRes) => {

        if (eRes.data.list && eRes.data.list.length > 0) {
          // this.list = eRes.data.list

          this.list = getMoreListData(eRes.data.list, this.list, this.pageNum);

        } else {
          this.isNoMore = true;
        }

        this.allPage = eRes.data.pageCount;
        this.allListCount = eRes.data.totalCount;

      }).catch().finally(() => {
        this.isLoading = false;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  padding-top: 67px !important;
  padding-bottom: 20px !important;
}

.header-image {
  position: relative;
  top: 0;

  .about-info {
    position: absolute;
    top: 0px;
    color: #ffffff;
    width: 100%;
    height: 100%;

    .about-info-title {
      font-size: 34.31px;
      position: absolute;
      top: 138px;
      left: 100px;
    }
  }

  .about-info-xs {
    position: absolute;
    top: 0px;
    //left: 100px;
    color: #ffffff;
    width: 100%;
    height: 100%;

    .about-info-title {
      font-size: 24.31px;
    }
  }
}

.row {
  margin: 0 !important;
}

.col {
  padding: 0 !important;
}

.main {
  line-height: 1 !important;
  margin-top: 86px;


  //margin-left: 46px;

  .join-search {

    width: 100%;
    //width: 802px;
    height: 55px;
    display: flex;
    align-items: center;
    border: 2px solid #cecece;
    border-radius: 30px;
    position: relative;
    padding-left: 15px;
    border-right: none;

    .icon {
      font-size: 24px !important;
      color: #cecece;
      //margin-top: 12px;
    }

    .input-poster {
      color: #000000;
      //width: 603px;
      width: 77%;
      height: 100%;
      outline: none;
      border: none;
    }

    .input-poster::placeholder {
      color: #cecece;
      font-size: 16.5px;
    }

    .btn-poster {
      height: 55px;
      width: 144px;
      background-color: #1d2088;
      color: #ffffff;
      font-size: 16.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 28px;
      position: absolute;
      right: 0;
      cursor: pointer;
      user-select: none;
    }
  }

  .list {
    //width: 802px;
    width: 100%;

    .list-item {
      margin-top: 51px !important;
      cursor: pointer;

      .list-item-title {
        font-size: 21px;
        color: #000000;
        line-height: 36px;
      }

      .list-item-info {
        color: #646464;
        line-height: 38px;
        font-size: 18px;
      }

      .join-list-item-desc {
        //color: #8f969f;
        //font-size: 13.5px;
        //line-height: 20px;
      }
    }
  }

  .detail {

    margin-top: 50px;

    .detail-title {
      font-size: 27.74px;
    }

    .detail-info {
      margin-top: 26px;
      font-size: 13.5px;
      color: #646464;
    }

    .detail-desc {
      margin-top: 58px;

      .desc-title {
        font-size: 21px;
        display: flex;
        align-items: center;

        .line {
          width: 5px;
          height: 19px;
          background-color: #376dc0;
        }

        .text {
          margin-left: 5px;
          margin-top: 2px;
        }
      }

      .join-desc-content {
        margin-top: 21px;

        //p {
        //  margin-bottom: 0 !important;
        //}

        //font-size: 13.5px;
        //color: #8f969f;
        //line-height: 20px;
      }
    }

    .detail-btn {
      margin-top: 53px;
      //width: 476px;
      height: 42px;
      background-color: #1d2088;
      font-size: 16.5px;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      color: #ffffff;
      border-radius: 5px;
      cursor: pointer;
      //padding: 14px 48px;

    }
  }
}
</style>

<style lang="scss">
.join-desc-content,.join-list-item-desc {
  p {
    margin-bottom: 0 !important;
  }
}
</style>
export default {
    yiti: {
        title: "Integrated Energy Solution for Optical Storage and Charging",
        desc: "We continuously explore new application scenarios, including but not limited to home energy reserves, commercial energy management, and industrial energy storage. At the same time, we provide more customized products and solutions based on the needs of different scenarios.",
    },

    dianwang: {
        title: "Advantages of a 24-Hour Microgrid",
        desc: {
            item1: {
                title: "Sustainability",
                desc: "Reduce dependence on traditional energy, decrease energy consumption and environmental pollution.",
            },
            item2: {
                title: "Stability",
                desc: "Operate stably and reliably in coordination with traditional power grids through intelligent energy management systems.",
            },
            item3: {
                title: "Efficiency",
                desc: "Reduce energy waste and power loss, achieving optimal energy allocation.",
            },
            item4: {
                title: "Flexibility and Adaptability",
                desc: "Adjust and optimize based on user needs and energy supply, adapting to different working conditions and environmental requirements.",
            },
        }
    },
}

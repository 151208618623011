export default {
    header: {
        title: "Hello, we are TC ENERTECH.",
        desc: "Tianchen Green Energy Technology (Wuhu) Co., Ltd. 「TC ENERTECH」 is a wholly-owned subsidiary of the listed company Tianchen Co., Ltd. (600620),",
        desc1: "specializing in energy storage battery products, with global leading technological strength and rich industry experience. Our products are widely used in energy storage systems, renewable energy, electric vehicles, and other fields, making us a leader in the energy storage battery industry."
    },

    gufen: {
        title: "Tianchen Co., Ltd.",
        desc: "Tianchen Co., Ltd. (stock code “600620.SH”) is one of the most historic and influential listed companies, established in 1989 and officially listed in 1992. With the belief that 'Vision Determines the Future' and the standard of sustainable development, the company has grown and diversified over the past 20 years to become a comprehensive listed company encompassing real estate, big health industry, transportation and logistics, and optical storage energy, among other sectors. Today, Tianchen Co., Ltd. has established over 50 enterprises in various fields such as real estate, commerce, and energy, with asset scale reaching over tens of billions. In 2023, the group officially entered the new energy sector, aiming to fully integrate the group's quality resources and strive to become an industry benchmark in the short term, creating another important industry for the group's development."
    },

    lvse: {
        title: "Tianchen Green Energy Technology (Wuhu) Co., Ltd.",
        desc: "Tianchen Green Energy Technology (Wuhu) Co., Ltd. is a wholly-owned subsidiary of Tianchen Co., Ltd. (stock code: 600620), headquartered in Shanghai. It is a technology company dedicated to providing diversified energy solutions for different scenarios. The company offers comprehensive energy services across the entire chain, including solution design, project investment and construction, system integration, and intelligent operation and maintenance. It also provides a full range of energy storage products such as energy storage photovoltaic panels, energy storage systems, inverters, and EMS."
    },

    wenhua: {
        title: "Tianchen Culture",
        pic: [
            {
                title: "Our Philosophy",
                content: "Inherent in Technology, Extraordinary by Nature",
            },
            {
                title: "Our Goal",
                content: "To become the world's leading energy technology company",
            },
        ]
    },
}

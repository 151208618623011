export default {
    aboutInfo: {
        title: "Technology is naturally extraordinary and natural",
        desc: "Tech Makes Extraordinary",
    },

    part1: {
        title: "NEWS",
        newsType1: "Company News",
        newsType2: "Industry Dynamics",
    },
    loadMore: "Learn more",
    noNoMore: "No more",
}
// 当前语言
import {language} from "@/const/language";

const languageDataList = [
    {
        text: "简体中文",
        value: "zh-CN",
    },
    {
        text: "English",
        value: "en-US",
    },
];

const curLanguage = {
    text: _lang().text,
    value: _lang().value
}

function _lang() {
    const langText = localStorage.getItem(language + '-text');
    const langValue = localStorage.getItem(language + '-value');
    const curLang = {};

    if (langText && langValue) {
        curLang.text = langText;
        curLang.value = langValue;
    } else {
        curLang.text = languageDataList[0].text
        curLang.value = languageDataList[0].value
    }

    return curLang;
}

export {
    languageDataList,
    curLanguage
};
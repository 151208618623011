<template>
  <div class="my-container">
    <div class="homePage" v-if="isStart">

      <!-- 广告语 -->
      <div class="slogan d-flex justify-center">
        <div class="content d-flex flex-column align-center">
          <!--          <div class="logo animated fadeInUp50 dur1-2">-->
          <!--            <v-img-->
          <!--                :max-width="170"-->
          <!--                src="@/assets/images/home/logo.png"-->
          <!--            ></v-img>-->
          <!--          </div>-->
          <div id="title" class="animated fadeInUp50 dur1-2"
               :style="{letterSpacing: languageStore.curLanguage.text=='English' ? '0px !important' : '31px !important'}">
            {{ $t('home.title') }}
          </div>
          <div id="desc" v-show="isShowTitle" :class="{en:languageStore.curLanguage.text=='English'}">{{
              $t('home.desc')
            }}
          </div>

          <div class="more animated fadeInUp50 dur1-2 del04">
            <v-btn
                class="btn animated-eve-btn trans3"
                rounded
                color="#ffffff"
                width="177"
                height="42"
                :text="true"
                elevation="0"

                @click="handleMore()"
            >
              <span class="txt-out trans7">{{ $t('home.learnMoreBtn') }}</span>
              <span class="txt-in trans3" :class="['trans-del'+index]" v-for="(item,index) in $t('home.learnMoreBtn')"
                    :key="index">{{ item }}</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="shadow animated fadeIn dur2"></div>
      <!-- 背景切换控制按钮 -->
      <div class="bgBtns d-flex align-center justify-center animated fadeIn del1">
        <div class="item d-flex align-center justify-center" v-for="(item,index) in homeBgList.length" :key="index"
             :class="{active:bgIndex==index}" @click="handlerChangeBgImg(index, false)"></div>
      </div>
    </div>
    <div class="homeBg" >
      <div class="item" v-for="(item,i) in homeBgList" :key="i">
        <img :src="item.src"/>
      </div>
    </div>
    <!-- 更多Page -->
    <div class="morePage" v-if="morePageVisible">
      <div class="box d-flex align-center justify-center" :style="{width: $vuetify.breakpoint.smAndDown ? '90% !important' : ''}">
        <div class="logo animated fadeIn del09 dur1-2">
          <v-img
              :max-width="180"
              src="@/assets/images/logo.png"
          ></v-img>
        </div>
        <div class="intro">
          <div class="tit animated fadeInUp50 del05">{{ $t('home.moreTit') }}</div>
          <div class="ipt animated fadeInUp50 del07">
            <v-form
                class="form d-flex flex-column"
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-text-field :label="$t('home.morePhone')" v-model="form.mobile" :rules="form.mobileRules">
              </v-text-field>
            </v-form>
          </div>
          <div class="tips animated fadeInUp50 del09">{{ $t('home.moreTips') }}</div>
          <!--          提交-->
          <v-btn class="submit animated fadeInUp50 del1-1 animated-eve-btn" :disabled="!valid" @click="handleSubmit">
            <span class="txt-out trans7">{{ $t('home.moreSend') }}</span>
            <span class="txt-in trans3" :class="['trans-del'+index]" v-for="(item,index) in $t('home.moreSend')"
                  :key="index">{{ item }}</span>
          </v-btn>
        </div>
      </div>
      <div class="hit" @click="onCloseMore"></div>
    </div>
    <!--    预约提示-->
    <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
        :top="true"
    >
      {{ $t('home.moreSendSucc') }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {BookAdd} from "@/api/book";

import {gsap} from 'gsap';
import {CSSPlugin} from 'gsap/CSSPlugin'
import {SplitText} from '@/plugins/SplitText'
import {useLanguage} from '@/stores'
import $ from "@/plugins/jqueryVender.js"

let myTimer = null;
// 注册插件
gsap.registerPlugin(SplitText);
gsap.registerPlugin(CSSPlugin);
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',

  props: {},

  computed: {
    languageStore() {
      return useLanguage();
    },
  },

  watch: {
    switchingNum(newVal, oldVal) {
      console.log('newVal', newVal, 'oldVal', oldVal);
      if (newVal >= 2) {
        this.switchingNum = 0;
        this.isSwitching = false;
      }
    }
  },

  data() {
    return {
      isStart: false,
      isShowTitle: false,
      multiLine: true,

      // 提示
      snackbar: false,

      homeBgList: [
        {src: require("@/assets/images/home/20240327/1.jpg")},
        {src: require("@/assets/images/home/20240327/2.jpg")},
        {src: require("@/assets/images/home/20240327/3.jpg")},
        {src: require("@/assets/images/home/20240327/4.jpg")},
        {src: require("@/assets/images/home/20240327/5.jpg")},
        {src: require("@/assets/images/home/20240327/6.jpg")},
      ],

      valid: true,
      form: {
        mobile: '',
        mobileRules: [
          v => /^1[3-9]\d{9}$/.test(v) || this.$t('book.form.input.mobile.placeholder')
        ],
      },

      // 当前背景 index
      bgIndex: 0,
      // 上一张背景 index
      preBgIndex: 0,
      // 切换中(控制按钮点击)
      isSwitching: false,
      // 切换次数(控制按钮点击)
      switchingNum: 0,

      model: 0,
      carouselDataList: [],

      morePageVisible: false,
    }
  },

  created() {
    // this.isMobile = this.$vuetify.breakpoint.mobile
  },

  mounted() {
    this.lang = this.$i18n.locale;
    console.log('this.lang:', this.lang)


    useLanguage().$subscribe((mutation, state) => {
      const element = document.getElementById('desc');
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
      element.innerHTML = this.$t('home.desc')
      this.addAnimate();
      console.log("# watch-----------languageStore:", this.languageStore.lang, mutation, state.lang);
    })
    // 背景图处理
    let $item = $(".homeBg .item");
    $item.hide();
    $item.eq(0).show();
    this.autoPlayBg();

    //$item.eq(0).css({"z-index":2});
    gsap.set($item.eq(0), {
      opacity: 0,
      scaleX: 1.2,
      scaleY: 1.2,
      // "-webkit-filter": "brightness(15)",
      // "filter": "brightness(5)"
    })
    gsap.to($item.eq(0), {
      duration: 2,
      opacity: 1,
      // "-webkit-filter": "brightness(1)",
      // "filter": "brightness(1)",
      x: 0
    })

    gsap.to($item.eq(0), {
      duration: 7, scaleX: 1, scaleY: 1,
    })

    const timer1 = setTimeout(() => {
      clearTimeout(timer1);

      this.isStart = true;
      const timer2 = setTimeout(() => {
        clearTimeout(timer2);

        this.addAnimate();
        this.isShowTitle = true;
      }, 100)
    }, 1000)
    //languageStore.$subscribe()
  },

  methods: {

    // 提交预约表单
    handleSubmit() {
      // this.snackbar = true;
      let valid = false;
      valid = this.$refs.form.validate();

      if (valid) {
        BookAdd({
          mobile: this.form.mobile
        }).then((sRes) => {
          console.log("sRes", sRes)
          this.snackbar = true;
        }).catch().finally();
      }
      console.log("val", valid);
    },

    // 更换背景
    handlerChangeBgImg(index, type) {
      if (this.bgIndex === index) {
        return false;
      }

      if (this.isSwitching) {
        return false;
      }

      this.isSwitching = true;

      if (index !== "null" && this.bgIndex == index) return;
      // 位移方向
      //let dir = "right";
      this.preBgIndex = this.bgIndex;


      if (type == "auto") {
        this.bgIndex++;
      } else {
        this.bgIndex = index;
      }

      //if(this.bgIndex < this.preBgIndex)dir = "left"
      if (this.bgIndex >= this.homeBgList.length) this.bgIndex = 0;
      let $item = $(".homeBg .item").eq(this.bgIndex);
      let $preItem = $(".homeBg .item").eq(this.preBgIndex);
      $(".homeBg .item").hide();
      $(".homeBg .item").css({"z-index": 0})
      $item.show().css({"z-index": 2});
      $preItem.show().css({"z-index": 1});

      let $itemSetX = "100%";

      if (this.bgIndex < this.preBgIndex) {
        $itemSetX = "-100%";
      } else {
        $itemSetX = "100%";
      }

      gsap.set($item, {
        opacity: 1,
        x: $itemSetX,
        scaleX: 1,
        scaleY: 1,
        // "-webkit-filter": "brightness(5)",
        // "filter": "brightness(5)"
      })

      gsap.to($preItem, {
        duration: 0.8,
        opacity: 0,
        // "-webkit-filter": "brightness(5)",
        // "filter": "brightness(5)",
        x: 0,
        ease: "cubic.inOut",
        onComplete: () => {
          console.log("$preItem-complete", this.switchingNum);
          this.switchingNum++;
        }
      })

      // gsap.to($item, {
      //   duration: 1.5,
      //   opacity: 1,
      // })

      gsap.to($item, {
        duration: 0.8,
        // "-webkit-filter": "brightness(1)",
        // "filter": "brightness(1)",
        x: 0,
        ease: "cubic.inOut",
        onComplete: () => {
          console.log("$item-complete", this.switchingNum);
          this.switchingNum++;
        }
      })

      // gsap.to($item, {
      //   duration: 7,
      //   scaleX: 1,
      //   scaleY: 1
      // })
      //console.log('#-------dir:', dir, this.preBgIndex, this.bgIndex, index)

      if (myTimer) {
        clearTimeout(myTimer)
        myTimer = null;
      }
      this.autoPlayBg();
      //this.bgIndex = index;
    },

    // 自动播放
    autoPlayBg() {
      // console.log('auto pay bg...');
      // return false;
      if (myTimer) {
        clearTimeout(myTimer)
        myTimer = null;
      }
      myTimer = setTimeout(() => {
        this.handlerChangeBgImg("null", "auto");
        //this.autoPlayBg("null","auto");
      }, 5000)
    },

    addAnimate() {
      console.log('#-------------ele1:')
      const timeline = new gsap.timeline()
      const text = new SplitText("#desc");
      gsap.set(text.chars, {
        perspective: 400
      })
      timeline.from(text.chars, {
        duration: 1.1,
        opacity: 0,
        y: 50,
        ease: "back",
        scale: 0,
        rotationX: 180,
        transformOrigin: "0% 50% -50",
        stagger: 0.07
      })
    },

    // 跳转至预约页面
    handleMore() {
      this.morePageVisible = true;
      const timer = setTimeout(() => {
        clearTimeout(timer);

        gsap.set(".morePage", {opacity: 0})
        gsap.set(".morePage .box", {x: -420})
        gsap.to(".morePage", {duration: .7, opacity: 1})
        gsap.to(".morePage .box", {duration: .7, delay: 0.3, x: 0, ease: "cubic.inOut"})
      }, 60)
    },

    // 关闭更多页面
    onCloseMore() {
      gsap.to(".morePage .box", {duration: 1, x: -420, ease: "expo.inOut"})
      gsap.to(".morePage", {
        duration: .5, delay: 0.7, ease: "cubic.out", opacity: 0, onComplete: () => {
          this.morePageVisible = false;
        }
      })
    }

  }
}
</script>

<style scoped lang="scss">

.my-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.homePage {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
  top: 0;

  .header {
    z-index: 1;
    position: absolute;
    top: 0;

    width: 100%;
    //padding: 20px 0;

    .change_language {
      cursor: pointer;
      position: absolute;
      right: 50px;
      top: 25px;
      //margin-right: 70px;
      font-size: 16px;
      color: #ffffff;

      .iconfont {
        font-size: 26px;
        display: block;
        margin-right: 10px;
      }

      //&:hover{color: black;}
    }
  }

  .shadow {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(33, 71, 116, .3), rgba(0, 0, 0, 0));
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .bgBtns {
    width: 100%;
    height: 30px;
    position: absolute;
    z-index: 10;
    bottom: 5%;

    .item {
      width: 44px;
      height: 44px;
      margin: 0 8px;
      cursor: pointer;

      &::after {
        content: " ";
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background-color: rgba(255, 255, 255, .5);
      }

      &.active:after {
        background-color: white;
      }

      &:hover:after {
        background-color: white;
      }
    }
  }

  .slogan {
    margin-top: 86+67px;
    z-index: 2;
    position: absolute;
    top: 0;

    width: 100%;

    .content {
      color: #ffffff;

      .logo {
      }

      #title {
        font-size: 56.2px !important;
        //letter-spacing: 31px !important;
        line-height: 1;

        @media only screen and (max-width: 600px) {
          font-size: 50px !important;
          letter-spacing: 20px !important;
        }
      }

      #desc {
        //font-size: 60px;
        font-size: 24.1px !important;
        //font-weight: normal !important;
        margin-top: 16px;
        //width: 100%;
        height: 50px;
        letter-spacing: 10px !important;

        @media only screen and (max-width: 600px) {
          font-size: 18px !important;
          letter-spacing: 5px !important;
        }

        &.en {
          letter-spacing: 6px !important;
          font-weight: normal;
        }
      }

      .more {
        font-size: 12.3px;
        margin-top: 40px;

        .btn {
          border: 1px solid;
          font-size: 16px;


          &:hover {
            border-color: black !important;
            background-color: black;
          }
        }
      }
    }
  }
}

.homeBg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;

  .item {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    //filter: brightness(5);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}

//更多Page
.morePage {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  opacity: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .35);

  .hit {
    width: 100%;
    height: 100%;
  }

  .box {
    width: 420px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: white;
    color: black;

    .tit {
      font-size: 24px;
    }

    .logo {
      width: 139px;
      height: 35px;
      position: absolute;
      left: 40px;
      top: 15px;
    }

    .intro {
      height: 400px;
      padding: 0 40px;
    }

    .ipt {
      margin-top: 20px;
    }

    .tips {
      margin-top: 25px;
      font-size: 16px;
    }

    .submit {
      width: 343px;
      height: 50px;
      margin-top: 25px;
      color: white;
      background-color: black;
      border-radius: 50px;
    }
  }
}
</style>

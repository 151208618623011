<template>
  <v-container fluid class="py-0 px-0">

    <div class="header-image">
      <v-img class="" src="@/assets/images/news/1.jpg" width="100%"></v-img>
      <div class="about-info-xs" v-if="$vuetify.breakpoint.smAndDown">
        <v-row>
          <v-col cols="2"></v-col>
          <v-col cols="8" class="pa-0">
            <div class="about-info-title text-center">{{ $t("news.aboutInfo.title") }}</div>
            <div class="about-info-desc text-center">{{ $t("news.aboutInfo.desc") }}</div>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </div>

      <div class="about-info" v-else>
        <v-row>
          <v-col cols="2"></v-col>
          <v-col cols="8" class="pa-0">
            <div class="about-info-title text-center">{{ $t("news.aboutInfo.title") }}</div>
            <div class="about-info-desc text-center">{{ $t("news.aboutInfo.desc") }}</div>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </div>
    </div>

    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="8" class="main">
        <v-row id="part1" justify="center" class="part1">
          <v-col class="news-title">{{ $t('news.part1.title') }}</v-col>
          <v-col v-if="!detailId" class="d-flex justify-end align-end">
            <div class="news-type" :class="[newsTypeIndex === index ? 'active' : '']" v-for="(item, index) in cateList"
                 :key="index" @click="handleCate(index)">
              {{ item.title }}
            </div>
          </v-col>
        </v-row>

        <!-- 列表 -->
        <v-row id="part2" class="part2" :class="[detailId ? 'justify-center' : '']">
          <!--          列表-->
          <template v-if="!detailId">
            <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="l-item" v-for="(item2, index2) in list" :key="index2">
              <v-card class="card" @click="handleView(index2)">
                <v-img
                    :src="item2.image"
                    width="100%"
                ></v-img>
                <v-card-title class="l-item-title">{{ item2.title }}</v-card-title>
                <v-card-text class="l-item-desc">{{ item2.releaseAt }}</v-card-text>
              </v-card>
            </v-col>
          </template>
          <!--          详情-->
          <template v-else>
            <v-col cols="10">
              <div class="detail">
                <div class="detail-info">
                  <div class="detail-info-date">{{ detail.releaseAt }}</div>
                  <div class="detail-info-title">{{ detail.title }}</div>
                </div>
                <div class="news-detail-content" v-html="detail.content"></div>
              </div>
            </v-col>
          </template>
        </v-row>

        <!--      了解更多-->
        <template v-if="!detailId">
          <v-row id="part3" justify="center" class="part3">
            <v-btn class="btn" outlined @click="handleMore()" :loading="isLoading">
              {{ isNoMore ? $t('news.noNoMore') : $t('news.loadMore') }}
            </v-btn>
          </v-row>
        </template>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getMoreListData, isContain} from "@/util/util";
import {gsap} from "gsap";
import {NewsList, NewsView} from "@/api/news";
import {NewsCateAll} from "@/api/newsCate";
// import {
//   useMessage
// } from "@/stores/modules/message"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "News",

  data() {
    return {
      list: [],

      isScroll: false,

      isLoading: false,

      // 页码
      page: 1,

      // 分页偏移量
      pageSize: 10,

      // 当前新闻类型下标
      newsTypeIndex: 1,

      cateList: [],

      // 没有更多
      isNoMore: false,

      detail: {
        id: '',
        content: ''
      },

      detailId: false,
    }
  },

  mounted() {
    // console.log("this.$router", this.$route.params.id);
    window.scrollTo(0, 0);

    // 资讯中心
    gsap.from(".part1", {
      y: -30,
      opacity: 0,
      duration: 1
    })

    this._animateHeaderImage();

    // window.onscroll = () => {
    //   this._partAnimate();
    // }

    // 设置详情 id
    this.detailId = this.$route.params.id;
    if (this.detailId) {
      this._getDetail();
      this._getNewsCateAll();
    } else {
      this._getNewsCateAll().then(() => {
        this._getList();
      }).catch().finally();
    }
  },

  activated() {
  },

  methods: {
    // 详情
    handleView(index) {
      this.detailId = this.list[index].id;
      // this.$router.push("/news/" + this.detailId);
      this.$router.push({
        path: "/news/" + this.detailId,
      });
      this._getDetail();
    },

    // 选择类型
    handleCate(index) {

      // 类型下标
      this.newsTypeIndex = index;

      this.list = [];
      this.page = 1;
      this.isNoMore = false;

      this._getList();
    },

    // 加载更多按钮
    handleMore() {
      // console.log("handleMore");
      if (this.isNoMore) {
        return;
      }
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this._getList();
    },

    _animateHeaderImage() {
      // 顶部图片
      gsap.from(".header-image", {
        y: 10,
        opacity: 0,
        duration: 1.5
      })

      gsap.from(".about-info", {
        y: 10,
        opacity: 0,
        duration: 1.5,
        delay: 0.5
      })
    },

    // 列表动画
    _part2Animate(list) {
      const timer = setTimeout(() => {
        clearTimeout(timer);

        let $dom = document.getElementsByClassName("l-item");

        let tmp = [];

        let num = 1;
        for (let i = $dom.length - 1; i >= 0; i--) {
          if (num <= list.length) {
            tmp.push($dom[i]);
          }
          num++
        }
        // console.log("tmp1", tmp);
        tmp = tmp.reverse();
        // console.log("tmp2", tmp);

        // let tl = gsap.timeline();
        for (let i = 0; i < tmp.length; i++) {
          gsap.from(tmp[i], {
            y: 0,
            duration: 0.8,
            delay: 0.5 * i
          })
          gsap.to(tmp[i], {
            opacity: 1,
            delay: 0.1 * i,
          })
        }
      }, 500)
    },

    // // 加载更多动画
    // _partAnimate() {
    //   const p = document.getElementById("part3");
    //   if (!p) {
    //     return false;
    //   }
    //   if (this.isScroll) {
    //     return false;
    //   }
    //   this.isScroll = true;
    //
    //   if (isContain(p)) {
    //     // console.log("show");
    //
    //     gsap.from("#part3", {
    //       y: 100,
    //       opacity: 0,
    //       duration: 1
    //     });
    //   } else {
    //     // console.log("hide")
    //   }
    // },

    // 列表数据
    _getList() {
      NewsList({
        newsType: this.cateList[this.newsTypeIndex].id,
        page: this.page,
        pageSize: this.pageSize,
      }).then((eRes) => {
        // console.log("NewsList", eRes);

        // useMessage().success("dddddd");

        if (eRes.data.list && eRes.data.list.length > 0) {
          // this.list = eRes.data.list

          this.list = getMoreListData(eRes.data.list, this.list, this.page);

          // console.log("this.list", this.list)

          this._part2Animate(eRes.data.list);

          this.page++
        } else {
          this.isNoMore = true;
        }

      }).catch().finally(() => {
        this.isLoading = false;
      });
    },

    // 新闻分类
    _getNewsCateAll() {
      this.isLoading = true;

      return new Promise((resolve, reject) => {
        NewsCateAll().then((sRes) => {
          // console.log("NewsCateAll1", sRes);
          this.cateList = sRes.data.all;
          resolve(sRes);
        }).catch((eRes) => {
          reject(eRes);
        }).finally();
      })
    },

    // 新闻详情
    _getDetail() {
      // 测试
      // this.detail.content = "<img src='/images/1.png' style='width: 100%' />";

      NewsView({
        id: this.detailId
      }).then((sRes) => {
        // console.log("getDetail", sRes);
        this.detail = sRes.data;
      }).catch().finally();
    }
  }

}
</script>

<style scoped lang="scss">

.container {
  padding-top: 67px !important;
  padding-bottom: 20px !important;
}

.header-image {

  position: relative;
  top: 0;

  .about-info {
    position: absolute;
    top: 77px;
    color: #ffffff;
    width: 100%;

    .about-info-title {
      font-size: 34.31px;
    }

    .about-info-desc {
      font-size: 16.88px;
      line-height: 21px;
      margin-top: 14px;
    }
  }
  .about-info-xs {
    position: absolute;
    top: 77px;
    color: #ffffff;
    width: 100%;

    .about-info-title {
      font-size: 24.31px;
    }

    .about-info-desc {
      font-size: 12.88px;
      line-height: 18px;
      margin-top: 10px;
    }
  }
}

.row {
  margin: 0 !important;
}

.col {
  padding: 0 !important;
}

.main {
  line-height: 1;
  padding: 0 !important;
  color: #000000;

  margin-top: 86px;

  .part1 {
    padding: 0 5px !important;

    .news-title {
      font-size: 20.81px;
      font-weight: bold;
    }

    .news-type {
      font-size: 11.25px;
      cursor: pointer;

      &.active {
        font-weight: bold;
      }

      &:first-child {
        margin-right: 29px;
      }
    }
  }

  .part2 {
    margin-top: 34px !important;

    .l-item {
      margin-bottom: 35px !important;
      padding: 0 5px !important;
      opacity: 0;

      .card {
        box-shadow: unset !important;
        border-radius: 0 !important;
        //width: 271px !important;
        //height: 207px !important;
      }

      .l-item-title {
        //padding: 17px 17px 17px 0 !important;
        font-size: 14.62px;
        margin-top: 18px !important;
        padding: 0 !important;
        line-height: 18px !important;
      }

      .l-item-desc {
        //padding: 0 9px 9px 0 !important;
        font-size: 11.25px;
        margin-top: 10px !important;
        padding: 0 !important;
        line-height: 1 !important;
      }
    }

    // 详情
    .detail-info {
      .detail-info-date {
        font-size: 15px;
        color: #908d89;
      }

      .detail-info-title {
        margin-top: 23px;
        font-size: 27.76px;
        line-height: 34px;
      }
    }

    .news-detail-content {
      margin-top: 55px;
    }
  }

  .part3 {
    margin-top: 62px !important;

    .btn {
      width: 153px;
      height: 35px;
      border: 1px solid #000000;
      border-radius: 0;
      background-color: rgba(1, 1, 1, 0);
      font-size: 12.37px !important;
    }
  }

}
</style>

<style lang="scss">
.news-detail-content{
  p {
    margin-bottom: 0 !important;
  }
}
</style>
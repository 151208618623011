<template>
  <v-container fluid class="px-0 py-0">

    <div class="header-image">
      <v-img class="" src="@/assets/images/contact/1.jpg" width="100%"></v-img>

      <div class="about-info-xs d-flex justify-center align-center flex-column px-10" v-if="$vuetify.breakpoint.smAndDown">
        <div class="about-info-title">{{ $t("contact.aboutInfo.title") }}</div>
        <div class="about-info-desc">
          <div class="">{{ $t("contact.aboutInfo.desc") }}</div>
          <div class="">{{ $t("contact.aboutInfo.desc1") }}</div>
        </div>
      </div>

      <div class="about-info" v-else>
        <div class="about-info-title">{{ $t("contact.aboutInfo.title") }}</div>
        <div class="about-info-desc">
          <div class="">{{ $t("contact.aboutInfo.desc") }}</div>
          <div class="">{{ $t("contact.aboutInfo.desc1") }}</div>
        </div>

      </div>

    </div>

    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="8" class="main">
        <v-row class="company d-flex flex-column align-lg-start align-center">
          <div class="company-title">{{ $t('contact.companyInfo.title') }}</div>
          <div class="company-desc d-flex align-center">
            <div class="company-address">{{ $t('contact.companyInfo.address') }}</div>
            <div class="company-map d-flex align-center">
              <a href="https://ditu.amap.com/place/B00155FXSP" target="_blank" class="d-flex align-center">
                <div class="iconfont icon-dingwei"></div>
                <div class="">{{ $t('contact.companyInfo.map') }}</div>
              </a>
            </div>
          </div>
        </v-row>

        <v-row class="contact">
          <!--          w:439 h:86-->
<!--          <v-col cols="12" xl="5" lg="5" md="12" class="pa-0 d-flex justify-lg-start justify-center mt-0">-->
<!--            <div class="contact-list" @click="handleContact(0)">-->
<!--              <div class="left">-->
<!--                <v-img src="@/assets/images/contact/icon-tel.png" max-width="34" height="39"></v-img>-->
<!--                <div class="contact-list-text">{{tel}}</div>-->
<!--              </div>-->
<!--              <div class="contact-list-icon">-->
<!--                <v-icon>mdi-chevron-right</v-icon>-->
<!--              </div>-->
<!--            </div>-->
<!--          </v-col>-->

          <v-col cols="12" xl="5" lg="5" md="12" class="pa-0 d-flex justify-lg-start justify-center mt-0">
            <div class="contact-list" @click="handleContact(1)">
              <div class="left">
                <v-img src="@/assets/images/contact/icon-email.png" max-width="34" height="39"></v-img>
                <div class="contact-list-text">{{email}}</div>
              </div>
              <div class="contact-list-icon">
                <v-icon>mdi-chevron-right</v-icon>
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="12" xl="5" lg="5" md="12" class="pa-0 d-flex justify-lg-end justify-center mt-4 mt-lg-0">
            <div class="contact-list" @click="handleContact(2)">
              <div class="left">
                <v-img src="@/assets/images/contact/icon-weixin.png" max-width="34" height="39"></v-img>
                <div class="contact-list-text">{{ $t('contact.companyInfo.wxTip') }}</div>
              </div>
              <div class="contact-list-icon">
                <v-icon>mdi-chevron-right</v-icon>
              </div>

            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>

    <v-dialog
        class="dialog"

        v-model="dialog"
        max-width="80%"
        transition="dialog-bottom-transition"
    >
      <div class="dialog-main">

        <v-tooltip top color="#376dc0">
          <template v-slot:activator="{ on, attrs }">
            <a :href="wxImgSrc" target="_blank">
              <v-img class="" :src="wxImgSrc" v-bind="attrs" v-on="on"></v-img>
            </a>
          </template>
          <span>{{ $t('product.detail.tips') }}</span>
        </v-tooltip>

      </div>
    </v-dialog>


  </v-container>
</template>

<script>

import {gsap} from 'gsap';
import {isContain} from '@/util/util.js';
import useClipboard from "vue-clipboard3";
import {useSnackbar} from "@/stores/modules/snackbar";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Contact",

  data() {
    return {
      isScroll: false,

      tel: "021-8888 8888",
      email: "tcenertech@tcenertech.com",

      dialog: false,

      wxImgSrc: require("@/assets/images/contact/wx.gif"),
    }
  },

  mounted() {

    window.scrollTo(0, 0);

    this._animateHeaderImage();

    // this._part3Animate();
    //
    // window.onscroll = () => {
    //   this._part3Animate();
    // }
  },

  methods: {
    async handleContact(index) {
      switch (index) {
        case 0:
          // 拨打电话
          window.open('tel:'+ this.tel, '_blank');
          break;
        case 1:
          // 复制
          try {
            await useClipboard().toClipboard(this.email)
            useSnackbar().openSnackbar({
              msg: "复制成功",
              color: 'success'
            });
          } catch (e) {
            console.error(e);
            console.error('复制失败')
          }
          break;
        case 2:

          this.dialog = true;

          break;
        default:
          break;
      }
    },

    _animateHeaderImage() {
      // 顶部图片
      gsap.from(".header-image", {
        y: 10,
        opacity: 0,
        duration: 1.5
      })

      gsap.from(".about-info", {
        y: 10,
        opacity: 0,
        duration: 1.5,
        delay: 0.5
      })
    },
  }
}

</script>

<style scoped lang="scss">
.container {
  padding-top: 67px !important;
  padding-bottom: 20px !important;
}

.header-image {
  position: relative;
  top: 0;

  .about-info {
    position: absolute;
    top: 240px;
    left: 120px;
    color: #ffffff;
    width: 100%;

    //&.about-info-md{
    //  top: 120px !important;
    //  left: 60px !important;
    //}

    .about-info-title {
      font-size: 34.31px;
    }

    .about-info-desc {
      font-size: 13.5px;
      line-height: 20.8px;
      margin-top: 16px;
    }
  }

  .about-info-xs {
    position: absolute;
    top: 0px;
    color: #ffffff;
    width: 100%;
    height: 100%;

    .about-info-title {
      font-size: 24.31px;
    }

    .about-info-desc {
      font-size: 10.5px;
      line-height: 15.8px;
      margin-top: 12px;
    }
  }
}

.row {
  margin: 0 !important;
}

.col {
  padding: 0 !important;
}

.main {
  line-height: 1;
  padding: 0 !important;

  margin-top: 86px;

  .company-title {
    font-size: 19.6px;
  }

  .company-desc {
    margin-top: 25px;
  }

  .company-address {
    font-size: 14.6px;
  }

  .company-map {
    margin-left: 36px;
    font-size: 15.3px;
    color: #1d2088;
    font-weight: bold;
    cursor: pointer;

    & > a {
      text-decoration-line: none;
    }

    .iconfont {
      margin-right: 8px;
    }
  }

  .contact {
    margin-top: 86px !important;

    .contact-list {
      border: 1px solid #d3d3d3;
      border-left: none;
      border-right: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //width: 469px;
      width: 100%;
      height: 86px;
      cursor: pointer;

      padding: 0 30px 0 20px;

      .left {
        display: flex;
        align-items: center;
      }

      .contact-list-text {
        margin-left: 16px;
        font-size: 14.6px;
        color: #000000;
      }
    }
  }
}
</style>
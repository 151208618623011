<template>
  <v-container fluid class="px-0 py-0">
    <div class="header-image">
      <v-img class="" src="@/assets/images/about/20240327/1.jpg" width="100%"></v-img>

      <div class="about-info-xs d-flex flex-column align-center justify-center" v-if="$vuetify.breakpoint.smAndDown">
        <div class="about-info-title text-center">{{ $t("about.header.title") }}</div>
        <div class="about-info-desc text-center px-10">
          <div class="">{{ $t("about.header.desc") }}</div>
          <div class="">{{ $t("about.header.desc1") }}</div>
        </div>
      </div>

      <div class="about-info" v-else>
        <div class="about-info-title text-center">{{ $t("about.header.title") }}</div>
        <div class="about-info-desc text-center">
          <div class="">{{ $t("about.header.desc") }}</div>
          <div class="">{{ $t("about.header.desc1") }}</div>
        </div>
      </div>
    </div>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10" class="main pa-0">

        <!--        股份-->
        <v-row id="gufen-title" class="gufen-title my-title" justify="center">{{ $t('about.gufen.title') }}</v-row>

        <v-row id="gufen-desc" class="gufen-desc d-flex justify-center">
                    <v-col cols="10">
          <div class="text-center">{{ $t('about.gufen.desc') }}</div>
                    </v-col>
        </v-row>

        <v-row id="gufen-pic" class="gufen-pic d-flex justify-center">
          <!--          <v-col cols="12" class="pa-0 justify-center">-->
          <v-img src="@/assets/images/about/20240327/gufen-pic.jpg" width="100%"></v-img>
          <!--          </v-col>-->
        </v-row>

        <!--        天宸绿色能源科技(芜湖）有限公司        -->
        <v-row id="lvse-title" class="lvse-title my-title d-flex justify-center">{{ $t('about.lvse.title') }}</v-row>

        <v-row id="lvse-desc" class="lvse-desc d-flex justify-center">
                    <v-col cols="10">
          <div class="text-center">{{ $t('about.lvse.desc') }}</div>
                    </v-col>
        </v-row>

        <v-row id="lvse-pic" class="lvse-pic">
          <!--          <v-col cols="12" class="pa-0">-->
          <v-img src="@/assets/images/about/20240327/3.png" width="100%"></v-img>
          <!--          </v-col>-->
        </v-row>

        <v-row id="lvse-pic-1" class="lvse-pic-1">
          <!--          <v-col cols="12" class="pa-0">-->
          <v-img src="@/assets/images/about/20240327/lvse-pic-1.jpg" width="100%"></v-img>
          <!--          </v-col>-->
        </v-row>

        <!--企业文化-->
        <v-row id="wenhua" class="wenhua" justify="center">
          <div class="wenhua-title">
            <div class="wenhua-title-txt my-title">{{ $t('about.wenhua.title') }}</div>
            <div class="wenhua-title-line"></div>
          </div>
        </v-row>

        <v-row id="wenhua-pic" class="wenhua-pic">
          <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="l-item" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'l-item-px']"
                 v-for="(item, index) in list"
                 :key="index">
            <div class="box">
              <div class="text">
                <div class="txt-1">{{ item.title }}</div>
                <div class="txt-2">{{ item.text }}</div>
              </div>
              <v-img class="image" :src="item.img" width="100%"></v-img>
            </div>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import {isContain} from '@/util/util.js';
import {gsap} from "gsap";
import {useLanguage} from "@/stores";

export default {
  name: "About",

  data() {
    return {
      // 图文列表
      list: [],
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    this._getList();

    this._watchGetList();

    this._animateHeaderImage();

    // this._partAnimate(0);
    // this._partAnimate(1);
    // this._partAnimate(2);
    // this._partAnimate(3);

    const arr = [
      {obj: 'gufen-title', isVisible: false,},
      {obj: 'gufen-desc', isVisible: false,},
      {obj: 'gufen-pic', isVisible: false,},
      {obj: 'lvse-title', isVisible: false,},
      {obj: 'lvse-desc', isVisible: false,},
      {obj: 'lvse-pic', isVisible: false,},
      {obj: 'lvse-pic-1', isVisible: false,},
      {obj: 'wenhua', isVisible: false,},
      {obj: 'wenhua-desc', isVisible: false,},
      {obj: 'wenhua-pic', isVisible: false,},
    ];
    window.onscroll = () => {
      for (let i = 0; i < arr.length; i++) {
        let temp = arr[i];
        this._partAnimate(temp, i);
      }
    }
  },

  methods: {
    _animateHeaderImage() {
      // 顶部图片
      gsap.from(".header-image", {
        y: 10,
        opacity: 0,
        duration: 1.5
      })

      // 你好，我们是天宸能科。
      gsap.from(".about-info", {
        y: 10,
        opacity: 0,
        duration: 1.5,
        delay: 0.8
      })
    },

    _partAnimate(obj, index) {
      let p = document.getElementById(obj.obj);
      // console.log("isContain", obj, p);
      if (!p) {
        return false;
      }

      // console.log("isContain");

      if (isContain(p)) {
        console.log("show", isContain(p));
        if (obj.isVisible) {
          return false;
        }
        obj.isVisible = true;

        gsap.from(p, {
          y: 50,
          opacity: 0,
          duration: 1.5,
          delay: parseFloat(index / 10 + 0.1),
        });

      } else {
        console.log("hide")
      }
    },

    _getList() {
      this.list = [
        {
          title: this.$t("about.wenhua.pic.0.title"),
          text: this.$t("about.wenhua.pic.0.content"),
          img: require('@/assets/images/about/20240327/wenhua-2.jpg')
        }, {
          title: this.$t("about.wenhua.pic.1.title"),
          text: this.$t("about.wenhua.pic.1.content"),
          img: require('@/assets/images/about/20240327/wenhua-1.jpg')
        },
      ]
    },

    // watch 监听 $t 切换
    _watchGetList() {
      useLanguage().$subscribe((mutation, state) => {
        // console.log("$subscribe", this.languageStore.lang, mutation, state.lang);
        this._getList();
      })
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  padding-top: 67px !important;
  padding-bottom: 20px !important;
}

.row {
  margin: 0 !important;
}

.col {
  padding: 0 !important;
}

//头图
.header-image {
  position: relative;
  top: 0;

  .about-info {
    position: absolute;
    top: 77px;
    color: #ffffff;
    width: 100%;

    .about-info-title {
      font-size: 34.31px;
    }

    .about-info-desc {
      font-size: 12.37px;
      line-height: 20.8px;
      margin-top: 30px;
    }
  }

  .about-info-xs {
    position: absolute;
    top: 0px;
    color: #ffffff;
    width: 100%;
    height: 100%;

    .about-info-title {
      font-size: 24.31px;
    }

    .about-info-desc {
      font-size: 10.37px;
      line-height: 15.8px;
      margin-top: 15px;
    }
  }
}

.main {
  line-height: 1 !important;

  .gufen-title {
    margin-top: 77px !important;
  }

  .gufen-desc {
    font-size: 13.5px;
    margin-top: 23px !important;
    line-height: 20.8px;
    color: #646464;

    //padding: 0 100px;
  }

  .gufen-pic {
    margin-top: 40px !important;
  }

  .lvse-title {
    margin-top: 77px !important;
  }

  .lvse-desc {
    font-size: 13.5px;
    margin-top: 23px !important;
    line-height: 20.8px;
    color: #646464;
    //padding: 0 100px;
  }

  .lvse-pic {
    margin-top: 40px !important;
  }

  .lvse-pic-1 {
    margin-top: 77px !important;
  }

  .wenhua {
    margin-top: 77px !important;

    .wenhua-title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .wenhua-title-txt {
    }

    .wenhua-title-line {
      @extend .title-line;
    }
  }

  .wenhua-pic {
    margin-top: 44px !important;

    .l-item {
      margin-bottom: 20px !important;


      &:first-child {
        padding-left: 0 !important;
      }

      &:last-child {
        padding-right: 0 !important;
      }

      &.l-item-px {
        padding: 0 27px !important;
      }

      .box {
        position: relative;

        .text {
          width: 100%;
          position: absolute;
          top: 61px;
          z-index: 1;
          color: #ffffff;
          text-align: center;
          padding: 0 24px;;

          .txt-1 {
            font-size: 28.13px;
            //line-height: 20px;
          }

          .txt-2 {
            margin-top: 22px;
            font-size: 13.5px;
            //line-height: 20px;
            font-weight: bold;
          }
        }

        .image {
          border-radius: 2px;
        }

      }
    }
  }

  .title-line {
    margin-top: 26px;

    width: 35%;
    height: 2px;
    background-color: #000000;
  }

  .my-title {
    font-size: 34.31px !important;
  }

}
</style>
<template>
  <v-snackbar top text v-model="visible" :color="color">
        {{ msg }}
    <!-- 关闭按钮 -->
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" v-if="showClose" icon @click="close" :color="color"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {useSnackbar} from "@/stores/modules/snackbar";

export default {
  name: "Snackbar",

  data() {
    return {
      msg: ""
    };
  },

  computed: {
    visible() {
      this.msg = useSnackbar().msg;
      return useSnackbar().visible;
    },

    showClose() {
      return useSnackbar().showClose;
    },

    color() {
      return useSnackbar().color;
    },
  },

  methods: {
    close() {
      useSnackbar().close();
    },
  },
};
</script>
<template>
  <v-container fluid class="px-0 py-0">

    <div class="header-image">
      <v-img src="@/assets/images/product/v100/header.jpg" width="100%"></v-img>
      <div class="about-info-xs d-flex align-center flex-column justify-center" v-if="$vuetify.breakpoint.smAndDown">
            <div class="about-info-title text-center">{{ $t("product.aboutInfo.title") }}</div>
            <div class="about-info-desc text-center">
              <div class="about-info-desc-txt">{{ $t("product.aboutInfo.desc") }}</div>
              <div class="about-info-desc-txt">{{ $t("product.aboutInfo.desc1") }}</div>
            </div>
      </div>

      <div class="about-info" v-else>
        <v-row>
          <v-col cols="2"></v-col>
          <v-col cols="8" class="pa-0">
            <div class="about-info-title text-center">{{ $t("product.aboutInfo.title") }}</div>
            <div class="about-info-desc text-center">
              <div class="about-info-desc-txt">{{ $t("product.aboutInfo.desc") }}</div>
              <div class="about-info-desc-txt">{{ $t("product.aboutInfo.desc1") }}</div>
            </div>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </div>
    </div>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10" class="main">

        <v-row class="part3">

          <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="image-item" v-for="(item,index) in list" :key="index">
            <v-img :src="item.img" width="100%" @click="handleOpenDetail(index)"></v-img>
            <v-row class="title">{{ item.title }}</v-row>
            <v-row class="desc">
              <v-row class="_desc">{{ item.desc }}</v-row>
            </v-row>
          </v-col>

        </v-row>

      </v-col>
      <v-col cols="1"></v-col>
    </v-row>

    <v-dialog
        class="dialog"

        v-model="dialog"
        max-width="1546"
        transition="dialog-bottom-transition"
    >
      <div class="dialog-main">
        <v-carousel
            height="100%"
        >
          <!--        <template v-slot:prev="{ on, attrs }">-->
          <!--          <v-btn-->
          <!--              color="success"-->
          <!--              v-bind="attrs"-->
          <!--              v-on="on"-->
          <!--          >Previous slide</v-btn>-->
          <!--        </template>-->
          <!--        <template v-slot:next="{ on, attrs }">-->
          <!--          <v-btn-->
          <!--              color="info"-->
          <!--              v-bind="attrs"-->
          <!--              v-on="on"-->
          <!--          >Next slide</v-btn>-->
          <!--        </template>-->

          <v-carousel-item
              v-for="(item,i) in detail"
              :key="i"
          >
            <v-tooltip top color="#376dc0">
              <template v-slot:activator="{ on, attrs }">
                <a :href="item.img" target="_blank">
                  <v-img class="" :src="item.img" width="100%"
                         v-bind="attrs"
                         v-on="on"
                  ></v-img>
                </a>
              </template>
              <span>{{ $t('product.detail.tips') }}</span>
            </v-tooltip>
          </v-carousel-item>

        </v-carousel>

        <!--        <div class="close">-->
        <!--          <span class="mdi mdi-close-circle-outline"></span>-->
        <!--        </div>-->
      </div>
    </v-dialog>

  </v-container>
</template>

<script>
import {gsap} from "gsap";
import {useLanguage} from "@/stores";

export default {
  name: "Product",

  components: {},

  data() {
    return {
      list: [],

      dialog: false,

      detail: [],

      // 详情列表
      detailList: [
        [
          {
            img: require("@/assets/images/product/v100/1-1.png"),
          }, {
            img: require("@/assets/images/product/v100/1-2.png"),
          }, {
            img: require("@/assets/images/product/v100/1-3.png"),
          }, {
            img: require("@/assets/images/product/v100/1-4.png"),
          },{
          img: require("@/assets/images/product/v100/3-1.png"),
        },
        ], [
          {
            img: require("@/assets/images/product/v100/2-1.png"),
          },
        ], [{
            img: require("@/assets/images/product/v100/3-2.png"),
          },
        ],
      ],
    }
  },

  computed: {
    languageStore() {
      return useLanguage();
    },
  },

  mounted() {
    window.scrollTo(0, 0)

    this._getList();

    this._watchGetList();

    this._animateHeaderImage();

    gsap.from(".part3", {
      y: 100,
      opacity: 0,
      duration: 1
    });
  },

  methods: {
    handleOpenDetail(i) {
      this.dialog = true;
      console.log("i", i)

      this.detail = this.detailList[i];
    },

    _animateHeaderImage() {
      // 顶部图片
      gsap.from(".header-image", {
        y: 10,
        opacity: 0,
        duration: 1.5
      })

      //
      gsap.from(".about-info", {
        y: 10,
        opacity: 0,
        duration: 1.5,
        delay: 0.8
      })
    },

    _getList() {
      this.list = [
        {
          img: require("@/assets/images/product/1.png"),
          title: this.$t("product.part3.0.title"),
          desc: this.$t("product.part3.0.content"),
        }, {
          img: require("@/assets/images/product/2.png"),
          title: this.$t("product.part3.1.title"),
          desc: this.$t("product.part3.1.content"),
        }, {
          img: require("@/assets/images/product/3.png"),
          title: this.$t("product.part3.2.title"),
          desc: this.$t("product.part3.2.content"),
        },
      ]
    },

    _watchGetList() {
      // watch 监听 $t 切换
      useLanguage().$subscribe((mutation, state) => {
        console.log("$subscribe", this.languageStore.lang, mutation, state.lang);
        this._getList();
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  padding-top: 67px !important;
  padding-bottom: 20px !important;
}

.row {
  margin: 0 !important;
}

.col {
  padding: 0 !important;
}

.header-image {

  position: relative;
  top: 0;

  .about-info {
    position: absolute;
    top: 77px;
    color: #ffffff;
    width: 100%;

    .about-info-title {
      font-size: 34.31px;
    }

    .about-info-desc {
      margin-top: 20px;
    }

    .about-info-desc .about-info-desc-txt {
      font-size: 12.37px;
      line-height: 20.8px;
    }
  }

  .about-info-xs {
    position: absolute;
    top: 0px;
    color: #ffffff;
    width: 100%;
    height: 100%;

    .about-info-title {
      font-size: 24.31px;
    }

    .about-info-desc {
      margin-top: 10px;
    }

    .about-info-desc .about-info-desc-txt {
      font-size: 10.37px;
      line-height: 15.8px;
    }
  }
}

.main {
  line-height: 1;
  padding: 0 !important;

  .part3 {
    margin-top: 86px !important;

    .image-item {
      padding: 0 15px !important;
      margin-bottom: 20px !important;
      cursor: pointer;

      .title {
        margin: 38px 0 0 0 !important;
        font-size: 14.62px !important;
        line-height: 18px;
        font-weight: bold;
      }

      .desc {
        margin: 18px 0 0 0 !important;

        ._desc {
          margin: 0 !important;
          font-size: 13.5px;
          color: #646464;
          line-height: 21px;
          text-align: justify;
        }
      }
    }
  }
}

.dialog-main {
  display: flex;

  .close {
    margin-left: 40px;

    .mdi {
      font-size: 40px;
    }
  }
}
</style>
import Vue from 'vue'    //1.导入vue2的构造函数
import VueRouter from 'vue-router'    //2.导入3.x路由的构造函数

import Home from '@/components/Home.vue'    //3.导入需要使用路由切换的组件，@表示根目录
// import Book from '@/components/Book.vue'
import About from "@/components/About";
import Product from "@/components/Product";
import Solution from "@/components/Solution";
import Contact from "@/components/Contact";
import News from "@/components/News";
import Project from "@/components/Project";
import Join from "@/components/Join";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)    //4.调用Vue.use()函数，把路由配置为Vue的插件

//5.创建路由对象
const router = new VueRouter({
    //5.1声明路由规则
    routes: [
        {path: '/', redirect: '/home'},
        {path: '/home', component: Home},
        // {path: '/book', component: Book},
        {path: '/about', component: About},
        {path: '/product', component: Product},
        {path: '/solution', component: Solution},
        {path: '/project', component: Project},
        {path: '/news/:id?', component: News},
        {path: '/contact', component: Contact},
        // 详情
        {path: '/join/:id(\\d+)?', component: Join},
        // 搜索
        {path: '/join/:search?', component: Join},
    ],
})

//6.向外导出路由对象
export default router
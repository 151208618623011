export default {
    listPart: {
        left: {
            title: "加入我们"
        },
        right: {
            search: {
                placeholder: '搜索职位',
                searchBtn: '搜索'
            },
            list: {
                money: "薪资"
            }
        }
    },

    detailPart: {
        money: "薪资",
        descTitle: "职位描述",
        detailBtn: {
            text: "简历投递",
            tips: "单击复制投递地址"
        }
    }
}
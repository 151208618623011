import home from './home'
import book from './book'
import about from './about'
import product from './product'
import project from './project'
import news from './news'
import contact from './contact'
import solution from './solution'
import nav from './nav'
import join from './join'

export default {
    home: home,
    book: book,
    about: about,
    product: product,
    project: project,
    news: news,
    contact: contact,
    solution: solution,
    nav: nav,
    join: join,
}

export function isContain(dom) {
    // 获取可视窗口的盖度。
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    // 获取滚动条滚动的高度
    const scrollTop = document.documentElement.scrollTop;
    // 获取元素偏移的高度。就是距离可视窗口的偏移量。
    const offsetTop = dom.offsetTop;
    return offsetTop - scrollTop <= screenHeight;
}

/**
 * 加载更多列表数据
 * @param {Object} resList 新列表数据
 * @param {Object} oldList 旧列表数据
 * @param {int} pageNo 当前页码
 */
export const getMoreListData = (resList, oldList, pageNo) => {
    // 如果是第一页需手动制空列表
    if (pageNo === 1) oldList = []
    // 合并新数据
    return oldList.concat(resList)
}
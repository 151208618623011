export default {
    aboutInfo: {
        title: "科技本然 非凡天成",
        desc: "Tech Makes Extraordinary",
    },

    part1: {
        title: "资讯中心",
        newsType1: "公司新闻",
        newsType2: "行业动态",
    },
    loadMore: "了解更多",
    noNoMore: "没有更多",
}
<template>
  <v-container fluid class="py-0 px-0">
    <div class="header-image">
      <video class="cover-video" poster="@/assets/images/solution/poster.png" src="@/assets/images/solution/1.mp4" :muted="true" :autoplay="true" :loop="true"></video>
    </div>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10" class="main">

<!--        一体式能源综合解决方案-->
        <v-row id="yiti-title" class="yiti-title my-title d-flex justify-center">{{ $t('solution.yiti.title') }}</v-row>

        <v-row id="yiti-desc" class="yiti-desc justify-center">
          <v-col cols="10">
            <div class="text-center">{{ $t('solution.yiti.desc') }}</div>
          </v-col>
        </v-row>

        <v-row id="yiti-pic" class="yiti-pic">
          <v-col cols="12" class="pa-0 justify-center">
            <v-img src="@/assets/images/solution/yiti-pic.jpg" width="100%"></v-img>
          </v-col>
        </v-row>

        <v-row id="dianwang-title" class="dianwang-title my-title" justify="center">
          <div class="d-flex flex-column align-center">
            <div class="text-center">{{ $t('solution.dianwang.title') }}</div>
            <div class="dianwang-title-line"></div>
          </div>
        </v-row>

        <v-row id="dianwang-desc" class="dianwang-desc" justify="center">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pa-0 px-5 mb-7">
            <div class="box d-flex justify-center flex-column">
              <div class="dianwang-desc-box-title">{{ $t('solution.dianwang.desc.item1.title') }}</div>
              <div class="dianwang-desc-box-desc">{{ $t('solution.dianwang.desc.item1.desc') }}</div>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pa-0 px-5 mb-7">
            <div class="box d-flex justify-center flex-column">
              <div class="dianwang-desc-box-title">{{ $t('solution.dianwang.desc.item2.title') }}</div>
              <div class="dianwang-desc-box-desc">{{ $t('solution.dianwang.desc.item2.desc') }}</div>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pa-0 px-5 mb-7">
            <div class="box d-flex justify-center flex-column">
              <div class="dianwang-desc-box-title">{{ $t('solution.dianwang.desc.item3.title') }}</div>
              <div class="dianwang-desc-box-desc">{{ $t('solution.dianwang.desc.item3.desc') }}</div>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pa-0 px-5 mb-7">
            <div class="box d-flex justify-center flex-column">
              <div class="dianwang-desc-box-title">{{ $t('solution.dianwang.desc.item4.title') }}</div>
              <div class="dianwang-desc-box-desc">{{ $t('solution.dianwang.desc.item4.desc') }}</div>
            </div>
          </v-col>

        </v-row>

<!--        <v-row class="part3" justify="center">-->
<!--          <v-img src="@/assets/images/solution/1.png" width="100%"></v-img>-->
<!--        </v-row>-->
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import {gsap} from "gsap";
import {isContain} from "@/util/util";

export default {
  name: "Solution",

  mounted() {
    window.scrollTo(0, 0);

    this._animateHeaderImage();

    const arr = [
      {obj: 'yiti-title', isVisible: false,},
      {obj: 'yiti-desc', isVisible: false,},
      {obj: 'yiti-pic', isVisible: false,},
      {obj: 'dianwang-title', isVisible: false,},
      {obj: 'dianwang-desc', isVisible: false,},
    ];
    window.onscroll = () => {
      for (let i = 0; i < arr.length; i++) {
        let temp = arr[i];
        this._partAnimate(temp, i);
      }
    }
  },

  methods: {
    _animateHeaderImage() {
      // 顶部图片
      gsap.from(".header-image", {
        y: 10,
        opacity: 0,
        duration: 1.5
      })
    },

    _partAnimate(obj, index) {
      let p = document.getElementById(obj.obj);
      // console.log("isContain", obj, p);
      if (!p) {
        return false;
      }

      // console.log("isContain");

      if (isContain(p)) {
        console.log("show", isContain(p));
        if (obj.isVisible) {
          return false;
        }
        obj.isVisible = true;

        gsap.from(p, {
          y: 50,
          opacity: 0,
          duration: 1.5,
          delay: parseFloat(index / 10 + 0.1),
        });

      } else {
        console.log("hide")
      }
    },
  }
}
</script>

<style scoped lang="scss">

.container {
  padding-top: 67px !important;
  background-color: #ffffff;
  padding-bottom: 20px !important;
}

.header-image {
  .cover-video {
    width: 100%;
    object-fit: cover;
  }
}

.row {
  margin: 0 !important;
}

.col {
  padding: 0 !important;
}

.main {
  line-height: 1;
  padding: 0 !important;

  .yiti-title{
    margin-top: 77px !important;
  }

  .yiti-desc{
    font-size: 13.5px;
    margin-top: 23px !important;
    line-height: 20.8px;
    color: #646464;
  }

  .yiti-pic{
    margin-top: 40px !important;
  }

  .dianwang-title {
    margin-top: 77px !important;
  }

  .dianwang-title-line {
    @extend .title-line;
  }

  .dianwang-desc {
    margin-top: 66px !important;

    font-size: 13.5px;
    line-height: 22px;

    .box {
      //width: 382px;
      height: 92px;
      border-left: 3px solid #9dc9d9;
      background-color: #fcfbff;
      padding: 0 29px 0 19px;

      .dianwang-desc-box-title {
        font-size: 16.88px;
        color: #000000;
      }
      .dianwang-desc-box-desc {
        color: #646464;
      }
    }
  }

  .title-line{
    margin-top: 26px;

    width: 35%;
    height: 2px;
    background-color: #000000;
  }

  .my-title {
    font-size: 34.31px !important;
  }

  //.part3 {
  //  margin-top: 56px !important;
  //}
}
</style>
// 第一个参数是应用程序中 store 的唯一 id
import {defineStore} from "pinia/dist/pinia";

/**
 * @param msg 信息
 * @param color snackbar 颜色
 * @param visible 是否可见
 * @param showClose 关闭按钮
 * @param timeout 停留持续时间
 */
export const useSnackbar = defineStore("snackbar", {
    state: () => {
        return {
            msg: '',
            color: '',
            visible: false,
            showClose: true,
            timeout: 5000,
        };
    },

    actions: {
        openSnackbar(options) {
            let timeout = this.timeout
            console.log("context, options", timeout, options)
            this._open({
                msg: options.msg,
                color: options.color
            })

            const timer = setTimeout(() => {
                clearTimeout(timer);

                this._close();
            }, timeout)
        },

        close() {
            this._close();
        },

        _open(options) {
            this.visible = true
            this.msg = options.msg
            this.color = options.color
        },

        _close() {
            this.visible = false
        },
    },
});

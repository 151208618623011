import Vue from 'vue'
import Vuei18n from 'vue-i18n'

import zh from './zh/index.js'
import en from './en/index.js'

import {
    curLanguage
} from '@/i18n/config.js'

Vue.use(Vuei18n)

const i18n = new Vuei18n({
    locale: curLanguage.value,
    messages: {  // 文案
        zh, // 文案的中文版
        en // 文案的英文版
    }
})
export default i18n

import {defineStore} from "pinia";
import {language} from "@/const/language";
import {
    curLanguage
} from '@/i18n/config.js'

import {
    languageDataList
} from '@/i18n/config.js'

// 第一个参数是应用程序中 store 的唯一 id
export const useLanguage = defineStore("language", {
    state: () => {
        return {
            curLanguage: {
                text: curLanguage.text,
                value: curLanguage.value  // 获取浏览器的语言
            }
        };
    },

    actions: {
        // 获取语言
        GetLanguage() {
            return this.curLanguage
        },

        // 设置语言
        SetLanguage(lang) {
            for (let i = 0; i < languageDataList.length; i++) {
                let tmp = languageDataList[i];
                if (lang === tmp.value) {
                    this.curLanguage.text = tmp.text;
                    localStorage.setItem(language + '-text', tmp.text)
                    break;
                }
            }

            this.curLanguage.value = lang;
            localStorage.setItem(language + '-value', lang)
        }
    },
});


